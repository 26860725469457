<template>
  <!-- reload -->
  <v-container class="cryptomation-container">
    <v-row dense v-show="allCartLoading">
      <v-col>
        <MainLoading />
      </v-col>
    </v-row>
    <v-row dense v-show="!allCartLoading">
      <v-col cols="12">
        <form
          id="payment-form"
          :action="paymentUrl"
          method="post"
          role="form"
          class="form-horizontal"
        ></form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getPaymentDetail } from "@/services/api/robot";
import MainLoading from "../subcomponents/mainLoading.vue";

export default {
  components: { MainLoading },
  name: "ChillPayMerchantPage",
  data: () => ({
    orderId: undefined,
    paymentUrl: "",
    scriptPaymentUrl: "",
    script_name: "modernpay-widget-container",
    allCartLoading: true,
  }),
  methods: {
    async createFormData() {
      let getPaymentForm = document.getElementById("payment-form");
      let modernPayTag = document.createElement("modernpay:widget");
      // let buttonChillpayTag = document.createElement("button");
      getPaymentForm.innerHTML = ""; // clear
      // get detail
      let paymentDetailResp = await getPaymentDetail(this.orderId);
      if (paymentDetailResp["code"] === 200) {
        let _paymentDetaildata = paymentDetailResp["data"]["chillPay"];
        //
        if (paymentDetailResp["data"]["environment"] === "PRODUCTION") {
          this.paymentUrl = process.env.VUE_APP_CHILL_PAY_URL_PROD;
          this.scriptPaymentUrl = process.env.VUE_APP_CHILL_PAY_SCRIPT_URL_PROD;
        } else {
          this.paymentUrl = process.env.VUE_APP_CHILL_PAY_URL;
          this.scriptPaymentUrl = process.env.VUE_APP_CHILL_PAY_SCRIPT_URL;
        }
        //
        modernPayTag.setAttribute(
          "data-merchantid",
          _paymentDetaildata["merchantid"]
        );
        modernPayTag.setAttribute(
          "data-amount",
          parseInt(_paymentDetaildata["amount"] * 100)
        );
        modernPayTag.setAttribute(
          "data-orderno",
          _paymentDetaildata["orderno"]
        );
        modernPayTag.setAttribute(
          "data-customerid",
          _paymentDetaildata["customerid"]
        );
        modernPayTag.setAttribute(
          "data-clientip",
          // _paymentDetaildata["clientip"]
          "127.0.0.1"
        );
        modernPayTag.setAttribute(
          "data-routeno",
          _paymentDetaildata["routeno"]
        );
        modernPayTag.setAttribute(
          "data-currency",
          _paymentDetaildata["currency"]
        );
        modernPayTag.setAttribute(
          "data-description",
          _paymentDetaildata["description"]
        );
        modernPayTag.setAttribute("data-apikey", _paymentDetaildata["apikey"]);
        modernPayTag.setAttribute("id", "modernpay-widget-container");
        modernPayTag.setAttribute("v-pre", "");
        // end of form setting

        // chillpaytag
        // buttonChillpayTag.setAttribute("type", "submit");
        // buttonChillpayTag.setAttribute("id", "btnSubmit");
        // buttonChillpayTag.setAttribute("value", "Submit");
        // buttonChillpayTag.setAttribute("class", "btn");

        // appendChil
        getPaymentForm.appendChild(modernPayTag);
        // getPaymentForm.appendChild(buttonChillpayTag);
        // create script
        let chillPayScript = document.createElement("script");
        chillPayScript.setAttribute("src", this.scriptPaymentUrl);

        chillPayScript.setAttribute("charset", "utf-8");
        chillPayScript.setAttribute("async", "");
        // add button
        document.head.appendChild(chillPayScript);
        this.allCartLoading = false;
      } else {
        this.redirectToRobotList();
      }
    },
    redirectToRobotList() {
      this.$router.push({
        path: "/tradingbot/",
      });
    },
  },
  computed: {},
  watch: {},
  mounted() {
    this.orderId = this.$route.query.order;
    // redirect
    if (this.orderId === undefined || this.orderId.length === 0) {
      this.redirectToRobotList();
    }
    this.createFormData();
  },
};
</script>

<style scoped></style>
